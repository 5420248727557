// Reducer
const SET_ALL_PATHS = "SET_ALL_PATHS";
const reducepaths = (state = [], action) => {
    switch (action.type) {
      case SET_ALL_PATHS:
        return action.paths;
      default:
        return state;
    }
  };

  
export default reducepaths;