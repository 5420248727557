import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
// applyMiddleware   处理使用中间件
// combineReducers   合并reducer
// thunk             处理异步

// 引入各个页面的reduce
import userReduce from "./UserReduce/userReduce";
import RoleReducer from "./Roledata/roleReduce";
import UserRoleReducer from "./UserRole/userRoleReduce";
import texReducer from "./textInp/texReaucer";
import newDataReducer from "./newDataPage/newDataReducer";
import PueReducer from "./pueReducer/reducer";
import reduces from "./AllTitles/AllTitlesReducer";
import reducepaths from "./Allpaths/AllpathReducer";

// 合并reducer
const reducer = combineReducers({
  user: userReduce,
  role: RoleReducer,
  tex: texReducer,
  userrole: UserRoleReducer,
  newDa: newDataReducer,
  pue: PueReducer,
  alltitle: reduces,
  allpath: reducepaths,
});

// 定义一个函数用于将需要持久化的状态数据保存到本地存储中
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      user: state.user,
      role: state.role,
      userrole: state?.userrole,
      newDa: state.newDa,
      pue: state.pue,
      alltitle: state.alltitle,
      allpath: state.allpath,
      // 其他需要持久化的仓库
    });
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
};

// 定义一个函数用于从本地存储中加载持久化的状态数据
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    // 如果localStorage中没有存储tex数据，则从默认值开始
    // 在这里添加，不需要持久化存储的数据
    return { ...state, tex: texReducer(undefined, {}) };
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

// 加载持久化的状态数据并应用中间件创建 Redux store
const persistedState = loadState();
const store = createStore(reducer, persistedState, applyMiddleware(thunk));

// 订阅 Redux store 的变化，并在变化时将状态数据保存到本地存储中
store.subscribe(() => {
  saveState(store.getState());
});

// 抛出仓库
export default store;
