const stateInit = {
  num: 0,
  deleteShow: false,
  newPageList: {},
  aggregateData: {},
  off: 0,
}

const newDataReducer = (state = stateInit, action) => {
  const newState = JSON.parse(JSON.stringify(state))

  switch (action.type) {
    case 'PLUS_NUM':
      newState.num++
      break
    case 'SUBTRACT_NUM':
      newState.num--
      break
    case 'TOLERANT_NUM':
      newState.num = action.data
      break
    case 'NEWPAGE_DATA':
      newState.newPageList = action.data
      break
    case 'SYSTEMPARAMETER_DATA':
      newState.aggregateData = action.data
      break
    case 'DETALESHOWORNOT-DETELE':
      newState.deleteShow = action.data
      break
    case 'DETALESHOWORNOT-DETELES':
      newState.deleteShow = action.data
      break
    case 'OFFICE':
      newState.off = action.data
      break

    default:
      return newState
  }

  return newState
}

export default newDataReducer
