//定义仓库
const stateInit = {
  userData: [],
};
const HomeReducer = (state = stateInit, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "USER_MESSAGE":
      newState.userData = action.data;
      break;
    default:
      return newState;
  }
  return newState;
};

export default HomeReducer;
