import React, { Fragment } from "react";
import ROUTER_LIST from "./router/index.js";
import { useRoutes } from "react-router-dom";
// import "@danfoss/mosaic/css/main.css";
// import "@danfoss/mosaic/css/link.css";

function App() {
  const element = useRoutes(ROUTER_LIST);

  return <Fragment>{element}</Fragment>;
}

export default App;
