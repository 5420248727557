import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'
import zh from './locales/zh.json'
const selectedLanguage = localStorage.getItem('i18nextLng') || 'en-US' // 从本地存储中读取用户选择的语言，默认为中文
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: en },
      zh: { translation: zh },
    },
    lng: selectedLanguage, // 设置默认语言为中文
    fallbackLng: 'en-US',
    preload: ['en-US', 'zh-CN'],
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
