// Reducer
const SET_ALL_TITLES = "SET_ALL_TITLES";
const reduces = (state = [], action) => {
    switch (action.type) {
      case SET_ALL_TITLES:
        return action.titles;
      default:
        return state;
    }
  };

  
export default reduces;