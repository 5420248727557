//定义角色仓库
const stateInit = {
    userRoleData: [],
  };
  const UserRoleReducer = (state = stateInit, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "ROLE_MESSAGE":
        newState.userRoleData = action.data;
        break;
      default:
        return newState;
    }
    return newState;
  };
  
  export default UserRoleReducer;
  