import React, { lazy } from 'react'
// 登录
const Login = lazy(() => import('../page/Login'))

// layout
const Lay = lazy(() => import('../page/Layout'))

// 首页
const Home = lazy(() => import('../page/Layout/Home'))

// 新建项目
const NewProject = lazy(() => import('../page/Layout/NewProject'))
// 新增数据
const NewData = lazy(() => import('../page/Layout/NewProject/newData'))
// 历史数据
const HistoricalData = lazy(() =>
  import('../page/Layout/NewProject/historicalData/index.jsx')
)

// 改造项目
// const RenovationProject = lazy(() =>
//   import("../page/Layout/RenovationProject")
// );

// 参考案例
// const ReferenceCase = lazy(() => import("../page/Layout/ReferenceCase"));

// 系统管理
const SystemManagement = lazy(() => import('../page/Layout/SystemManagement'))

// 创建用户
const CreateUser = lazy(() =>
  import('../page/Layout/SystemManagement/CreateUser')
)
// 用户列表
const UserList = lazy(() => import('../page/Layout/SystemManagement/UserList'))
// 待审核用户
const PendingUser = lazy(() =>
  import('../page/Layout/SystemManagement/PendingUser')
)
// 创建角色
const CreateRole = lazy(() =>
  import('../page/Layout/SystemManagement/CreateRole')
)
// 角色列表
const RoleList = lazy(() => import('../page/Layout/SystemManagement/RoleList'))

// 请求详情页面
const RequestPage = lazy(() => import('../page/RequestPage'))

// 404
const RequestTimeout = lazy(() => import('../page/error'))

// callback页面
const CallBack = lazy(() => import('../page/Callback'))

const ROUTER_LIST = [
  {
    path: '/',
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Login />
      </React.Suspense>
    ),
    icon: '',
  },
  {
    path: '/callback',
    element: <CallBack />,
    icon: '',
  },
  {
    path: '/lay',
    element: <Lay />,
    icon: '',
    meat: {
      show: true,
    },
    children: [
      {
        path: '/lay/home',
        element: <Home />,
        icon: '',
        name: 'layout.home',
        meat: {
          show: true,
        },
      },
      // {
      //   path: '/lay/newProject',
      //   element: <NewProject />,
      //   icon: '',
      //   name: 'layout.newProject',
      //   meat: {
      //     show: true,
      //   },
      // },
      // ---------------
      {
        path: '/lay/newData',
        name: 'newProject.newData',
        icon: '',
        element: <NewData />,
        meat: {
          show: true,
          project: true,
        },
      },
      {
        path: '/lay/historicalData',
        name: 'newProject.historyData',
        icon: '',
        element: <HistoricalData />,
        meat: {
          show: true,
          project: true,
        },
      },
      // ---------------
      // {
      //   path: "/lay/renovationProject",
      //   element: <RenovationProject />,
      //   icon: "",
      //   name: "layout.renovationProject",
      //   meat: {
      //     show: true,
      //   },
      // },
      // {
      //   path: "/lay/referenceCase",
      //   element: <ReferenceCase />,
      //   icon: "",
      //   name: "layout.referenceCase",
      //   meat: {
      //     show: true,
      //   },
      // },
      {
        path: '/lay/systemManagement',
        element: <SystemManagement />,
        icon: '',
        name: 'layout.systemManagement',
        meat: {
          show: true,
        },
      },
      // ---------------------
      {
        path: '/lay/createUser',
        element: <CreateUser />,
        icon: '',
        name: 'systemManagement.createUser',
        meat: {
          show: true,
          establish: true,
        },
      },
      {
        path: '/lay/userList',
        element: <UserList />,
        icon: '',
        name: 'systemManagement.userList',
        meat: {
          show: true,
          establish: true,
        },
      },
      {
        path: '/lay/pendingUser',
        element: <PendingUser />,
        icon: '',
        name: 'systemManagement.userToBeExamined',
        meat: {
          show: true,
          establish: true,
        },
      },
      {
        path: '/lay/createRole',
        element: <CreateRole />,
        icon: '',
        name: 'systemManagement.createRole',
        meat: {
          show: true,
          role: true,
        },
      },
      {
        path: '/lay/roleList',
        element: <RoleList />,
        icon: '',
        name: 'systemManagement.roleList',
        meat: {
          show: true,
          role: true,
        },
      },
      // -------------------
    ],
  },

  // 请求页面
  {
    path: '/RequestPage',
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <RequestPage />
      </React.Suspense>
    ),
    icon: '',
    meat: {
      show: true,
    },
  },
  // 请求超时页面
  {
    path: '/requestTimeout',
    element: <RequestTimeout />,
    icon: '',
    meat: {
      show: true,
    },
  },
]

export default ROUTER_LIST
