const stateInit = {
  nameType: false,
  yeZhuType: false,
  sheJiType: false,
  cityType: false,
  loadRateType: false,
  // 气象站数据
  stationData: [],
}
const texReducer = (state = stateInit, action) => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case 'NAME_TYPE':
      newState.nameType = action.data
      break
    case 'GET_STATION':
      newState.stationData = action.data
      break
    case 'YEZHU_TYPE':
      newState.yeZhuType = action.data
      break
    case 'SHEJI_TYPE':
      newState.sheJiType = action.data
      break
    case 'CITY_TYPE':
      newState.cityType = action.data
      break
    case 'LOADRATE_TYPE':
      newState.loadRateType = action.data
      break

    default:
      return newState
  }
  return newState
}

export default texReducer
