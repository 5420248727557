import React from 'react'
import ReactDOM from 'react-dom/client'
import './i18n.js'
import '@danfoss/mosaic/css/main.css'
import '@danfoss/mosaic/css/link.css'
import 'antd/dist/reset.css'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App.js'
import './index.css'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
