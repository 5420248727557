//定义pue仓库
const stateInit = {
  // 地区数据
  cityData: [],
  // 供电数据
  gdData: [],
  // 查询详情数据
  detailData: [],
  // 数据id
  pueDataId: 0,
  //是否显示copy和查看
  copyShow: false,
  // 查看是否显示，按钮是否改变
  checkShow: false,
  // 路由是否隐藏
  routerShow: true,
  // 计算后是否显示计算按钮
  cunBtn: false,
  // 要修改的数据设置状态
  alterList: false,
  // 是否清空页面一的数据
  pageCon: false,
  // 是否显示冷却塔
  showCooling: true,
}
const PueReducer = (state = stateInit, action) => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case 'GET_CITY':
      newState.cityData = action.data
      break

    case 'POWERSUPPLYDATA':
      newState.gdData = action.data
      break
    case 'PUEDATA':
      newState.pueDataId = action.data
      break
    case 'STORAGEDETAILDATA':
      newState.detailData = action.data
      break
    case 'COPY-ALTER':
      newState.copyShow = action.data
      break
    case 'CHECK-ALTER':
      newState.checkShow = action.data
      break
    case 'ROUTER-ALTER':
      newState.routerShow = action.data
      break
    case 'CUNBTNSHOW':
      newState.cunBtn = action.data
      break
    case 'ALTER-DATA':
      newState.alterList = action.data
      break
    case 'PAGESHOW':
      newState.pageCon = action.data
      break
    default:
      return newState
  }
  return newState
}

export default PueReducer
